import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../../shared/services/auth/auth.service"
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(private authService: AuthService, private fb: FormBuilder, public router: Router, public toastr: ToastrService) {
    this.loginForm = this.fb.group({
      email: [[Validators.required]],
      password: [Validators.required],
    });
  }

  ngOnInit() {
    if (localStorage.getItem('user')) {
      this.router.navigate(['/']);
  }
  }

  public email;
  public password;

  login() {
    // if (this.loginForm.value["email"] == "Test@gmail.com" && this.loginForm.value["password"] == "test123") {
    //   let user = {
    //     email: "Test@gmail.com",
    //     password: "test123",
    //     name: "test user",
    //   };
    //   localStorage.setItem("user", JSON.stringify(user));
    //   this.router.navigate(["/dashboard/default"]);
    // }
    this.authService.signIn(this.email, this.password).subscribe((res:any)=>{
      console.log(res)
      var cart =[]
      var success = res.success;

      if(success){
        var result = res.response.result;
        localStorage.setItem('cart', JSON.stringify(cart))
        localStorage.setItem('user', JSON.stringify(result));
        this.router.navigate(["/dashboard"]);
      }else{
        this.toastr.error(res.message)
      }
      
    })

  }

  showPassword(){
    this.show = !this.show
  }
}
