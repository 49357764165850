import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDatepickerModule  } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.scss']
})
export class DaterangepickerComponent {


  
  hoveredDate: NgbDate | null = null;

	@Input() fromDate: NgbDate | null;
	@Input() toDate: NgbDate | null;
  @Output() dateRangeChange = new EventEmitter<{ from: NgbDate | null, to: NgbDate | null }>();

	constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
		this.toDate = calendar.getToday();
		this.fromDate = calendar.getNext(calendar.getToday(), 'd', -30);
	}

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
    this.dateRangeChange.emit({ from: this.fromDate, to: this.toDate });
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}
  updateDateRange(dateRange: string) {
      const dateParts = dateRange.split(' - ');

      if (dateParts.length === 2) {
          const fromDateString = dateParts[0];
          const toDateString = dateParts[1];

          const fromDate = this.formatter.parse(fromDateString);
          const toDate = this.formatter.parse(toDateString);

          if (fromDate && toDate && this.calendar.isValid(NgbDate.from(fromDate)) && this.calendar.isValid(NgbDate.from(toDate))) {
              this.fromDate = NgbDate.from(fromDate);
              this.toDate = NgbDate.from(toDate);
          }
      }
  }
}



