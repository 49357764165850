<div class="media profile-media">
  <img class="b-r-10 media-object rounded-circle img-40" src="assets/images/alphabet_letters/svg/{{initialLetter(userName)}}.svg" alt="">
  <div class="media-body"><span>{{ userName }}</span>
    <p class="mb-0 font-roboto">Profile <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li><a routerLink="/user"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a routerLink="/user/address"><app-feather-icons [icon]="'map-pin'"></app-feather-icons><span>Address</span></a></li>
  <li><a routerLink="/user/reset-password"><app-feather-icons [icon]="'lock'"></app-feather-icons><span>Reset Password</span></a></li>
  <!-- <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>
</ul>