import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "product-list",
    loadChildren: () => import("../../components/product-list/product-list.module").then((m) => m.ProductListModule),
  },
  {
    path: "invoice-list",
    loadChildren: () => import("../../components/invoice-list/invoice-list.module").then((m) => m.InvoiceListModule),
  },
  {
    path: "outstanding",
    loadChildren: () => import("../../components/outstanding/outstanding.module").then((m) => m.OutstandingModule),
  },
  {
    path: "order-list",
    loadChildren: () => import("../../components/order-list/order-list.module").then((m) => m.OrderListModule),
  },
  {
    path: "payment-done",
    loadChildren: () => import("../../components/payment-done/payment-done.module").then((m)=> m.PaymentDoneModule),
  },
  {
    path: "user",
    loadChildren: () => import("../../components/user/user.module").then((m)=> m.UserModule),
  },
  {
    path: "cart",
    loadChildren: () => import("../../components/cart/cart.module").then((m)=> m.CartModule),
  },
  {
    path: "icons",
    loadChildren: () => import("../../components/icons/icons.module").then((m) => m.IconsModule),
  },
  
];
