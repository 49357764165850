import { Component, OnInit } from '@angular/core';
import { CartService } from '../../../../services/cart.service'
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  public openCart: boolean = false;
  public cart;
  public totalAmount;
  
  qtyChEvent = new Subject<any>();
  qtyChSubscription: Subscription;

  constructor(private cartService: CartService, private toastr: ToastrService) { 
    this.qtyChSubscription = this.qtyChEvent.pipe(debounceTime(800)).subscribe(({ event, product }) => {
      this.qtyChange(product, (event.target as HTMLInputElement).value);
      console.log(product, (event.target as HTMLInputElement).value);
      
    });
  }

  ngOnInit() {
    this.getCart()
  }

  // For Mobile Device
  toggleCart() {
    this.openCart = !this.openCart;
  }

  getCart(){
    
    this.cartService.getCart().subscribe(cart =>{
      this.cart = cart;
      this.totalAmount = 0;
      this.cart.forEach((element)=>{
        this.totalAmount = this.totalAmount + element.prod_total;
      })
    })
    
  }
  
  incrementCart(product){
    console.log(product);

    if(product.schemeSelected?.length >= 1){
    console.log('....');
    let quantityChange = false;
      product.schemeSelected.forEach(scheme =>{
        if(scheme.scheme_type_name === 'Trial Rate'){
            if(product.qty < scheme.trial_product_qty){
              this.cartService.incrementCart(product.product_name, product.pp_price);
              quantityChange = true;
              return;
            }else{
              quantityChange = true;
              this.toastr.error('Maximum Product Quantity Reached');
              return;
            }
          }
          else if(scheme.scheme_type_name === 'Slow'){
            quantityChange = true;
            this.cartService.incrementCart(product.product_name, product.pp_price);
            return;
          }
          else if(scheme.scheme_type_name === 'Buy “ABC” product of x1 to x2 quantity and get “ABC” product at Rs. X'){
            
            if(product.qty  >= scheme.scheme_qty_from - 1 && product.qty < scheme.scheme_qty_to){
                  product.pp_price = scheme.scheme_product_variant_price;
                  console.log(product.pp_price);
                  quantityChange = true;
                  this.cartService.incrementCart(product.product_name, product.pp_price);
                  return;
            } 
          }
          else if(scheme.scheme_type_name === 'Buy “ABC” product of x1 to x2 quantity and get “PQR” product at Rs. X'){
            if(product.qty  >= scheme.scheme_qty_from - 1 && product.qty < scheme.scheme_qty_to){
              this.cartService.incrementCart(product.product_name, product.pp_price);
              this.cart.forEach(prod =>{
                if(prod.product_id === scheme.scheme_product_id){
                  prod.pp_price = scheme.scheme_product_variant_price;
                  prod.qty = (product.qty ) * scheme.scheme_product_allocated_qty;
                  prod.prod_total = prod.qty * prod.pp_price
                  this.cartService.updateCart(prod);
                }
              })
              quantityChange = true;
              return;
            } 
          }
          else if(scheme.scheme_type_name === 'Special Rate Scheme'){
            if(product.qty < scheme.spa_allocated_qty){
              this.cartService.incrementCart(product.product_name, product.pp_price);
              quantityChange = true;
              return;
            }else{
              quantityChange = true;
              this.toastr.error('Maximum Product Quantity Reached');
              return;
            }
          }
          
      })
      if(!quantityChange){
        if(product.schemeSelected[0].scheme_type_name === 'Buy “ABC” product of x1 to x2 quantity and get “PQR” product at Rs. X'){
          this.cartService.incrementCart(product.product_name, product.pp_price);
              this.cart.forEach(prod =>{
                if(prod.product_id === product.schemeSelected[0].scheme_product_id){
                  prod.pp_price = product.schemeSelected[0].scheme_product_variant_price;
                  prod.qty = (product.qty) * product.schemeSelected[0].scheme_product_allocated_qty;
                  prod.prod_total = prod.qty * prod.pp_price
                  this.cartService.updateCart(prod);
                }
              })
              quantityChange = true;
              return;
        } else{
          if(product.oprice){
            product.pp_price = product.oprice;
            this.cartService.incrementCart(product.product_name, product.pp_price);
          }else{
            this.cartService.incrementCart(product.product_name, product.pp_price);
          }
        }
        
        
      }
    } else{
        this.cartService.incrementCart(product.product_name, product.pp_price);
    }
    
    this.getCart();
  }

  decrementCart(product){
    if(product.schemeSelected?.length >= 1){
      let quantityChange = false;
      product.schemeSelected.forEach(scheme =>{
          if(scheme.scheme_type_name === 'Trial Rate'){
            quantityChange = true;
            this.cartService.decrementCart(product.product_name,product.pp_price);
            return;
          }
          else if(scheme.scheme_type_name === 'Slow'){
            quantityChange = true;
            this.cartService.decrementCart(product.product_name,product.pp_price);
            return;
          }
          else if(scheme.scheme_type_name === 'Buy “ABC” product of x1 to x2 quantity and get “ABC” product at Rs. X'){
            
            if(product.qty > scheme.scheme_qty_from && product.qty <= scheme.scheme_qty_to + 1){
              product.pp_price = scheme.scheme_product_variant_price;
              quantityChange = true;
              this.cartService.decrementCart(product.product_name,product.pp_price);
            }
          }
          else if(scheme.scheme_type_name === 'Buy “ABC” product of x1 to x2 quantity and get “PQR” product at Rs. X'){
            if(product.qty > scheme.scheme_qty_from && product.qty <= scheme.scheme_qty_to + 1){
              this.cartService.decrementCart(product.product_name,product.pp_price);
              this.cart.forEach(prod =>{
                if(prod.product_id === scheme.scheme_product_id){
                  prod.pp_price = scheme.scheme_product_variant_price;
                  prod.qty = (product.qty ) * scheme.scheme_product_allocated_qty;
                  prod.prod_total = prod.qty * prod.pp_price
                  this.cartService.updateCart(prod);
                }
              })
              quantityChange = true;
              return;
            } 
          }
          else if(scheme.scheme_type_name === 'Special Rate Scheme'){
            quantityChange = true;
            this.cartService.decrementCart(product.product_name,product.pp_price);
            return;
          }
          
      })
      if(!quantityChange){
        if(product.schemeSelected[0].scheme_type_name === 'Buy “ABC” product of x1 to x2 quantity and get “PQR” product at Rs. X'){
          this.cartService.decrementCart(product.product_name,product.pp_price);
          this.cart.forEach(prod =>{
                if(prod.product_id === product.schemeSelected[0].scheme_product_id){
                  prod.pp_price = product.schemeSelected[0].scheme_product_variant_price;
                  prod.qty = (product.qty) * product.schemeSelected[0].scheme_product_allocated_qty;
                  prod.prod_total = prod.qty * prod.pp_price
                  this.cartService.updateCart(prod);
                }
              })
              quantityChange = true;
              return;
        } else{
          if(product.oprice){
            product.pp_price = product.oprice;
            this.cartService.decrementCart(product.product_name,product.pp_price);
          }else{
            this.cartService.decrementCart(product.product_name,product.pp_price);
          }
        }
        
      }
    }else{
      this.cartService.decrementCart(product.product_name,product.pp_price)
    }
    
    this.getCart()
  }

  qtyChange(product, value){
    if(product.schemeSelected?.length >= 1){
      console.log('....');
      let quantityChange = false;
        product.schemeSelected.forEach(scheme =>{
          if(scheme.scheme_type_name === 'Trial Rate'){
              if(value < scheme.trial_product_qty){
                this.cartService.qtyChange(product.product_name, parseInt(value), product.pp_price);
                quantityChange = true;
                return;
              }else{
                quantityChange = true;
                this.toastr.error('Maximum Product Quantity Reached');
                return;
              }
            }
            else if(scheme.scheme_type_name === 'Slow'){
              quantityChange = true;
              this.cartService.qtyChange(product.product_name, parseInt(value), product.pp_price);
              return;
            }
            else if(scheme.scheme_type_name === 'Buy “ABC” product of x1 to x2 quantity and get “ABC” product at Rs. X'){
              
              if(value >= scheme.scheme_qty_from && value <= scheme.scheme_qty_to){
                    product.pp_price = scheme.scheme_product_variant_price;
                    console.log(product.pp_price);
                    quantityChange = true;
                    this.cartService.qtyChange(product.product_name, parseInt(value), product.pp_price);
                    return;
              } 
            }
            else if(scheme.scheme_type_name === 'Buy “ABC” product of x1 to x2 quantity and get “PQR” product at Rs. X'){
              if(value >= scheme.scheme_qty_from && value <= scheme.scheme_qty_to){
                this.cartService.qtyChange(product.product_name, parseInt(value), product.pp_price);
                this.cart.forEach(prod =>{
                  if(prod.product_id === scheme.scheme_product_id){
                    prod.pp_price = scheme.scheme_product_variant_price;
                    prod.qty = (product.qty ) * scheme.scheme_product_allocated_qty;
                    prod.prod_total = prod.qty * prod.pp_price
                    this.cartService.updateCart(prod);
                  }
                })
                quantityChange = true;
                return;
              } 
            }
            else if(scheme.scheme_type_name === 'Special Rate Scheme'){
              if(value < scheme.spa_allocated_qty){
                this.cartService.qtyChange(product.product_name, parseInt(value), product.pp_price);
                quantityChange = true;
                return;
              }else{
                quantityChange = true;
                this.toastr.error('Maximum Product Quantity Reached');
                return;
              }
            }
            
        })
        if(!quantityChange){
          if(product.schemeSelected[0].scheme_type_name === 'Buy “ABC” product of x1 to x2 quantity and get “PQR” product at Rs. X'){
            this.cartService.qtyChange(product.product_name, parseInt(value), product.pp_price);
            this.cart.forEach(prod =>{
                  if(prod.product_id === product.schemeSelected[0].scheme_product_id){
                    prod.pp_price = product.schemeSelected[0].scheme_product_variant_price;
                    prod.qty = (product.qty) * product.schemeSelected[0].scheme_product_allocated_qty;
                    prod.prod_total = prod.qty * prod.pp_price
                    this.cartService.updateCart(prod);
                  }
                })
                quantityChange = true;
                return;
          } else{
            if(product.oprice){
              product.pp_price = product.oprice;
              this.cartService.qtyChange(product.product_name, parseInt(value), product.pp_price);
            }else{
              this.cartService.qtyChange(product.product_name, parseInt(value), product.pp_price);
            }
          }
          
        }
      } else{
        this.cartService.qtyChange(product.product_name, parseInt(value), product.pp_price);
      }

    this.getCart()
  }


  deleteCart(product){
    if(product.schemeSelected && product.schemeSelected[0].scheme_type_name === 'Buy “ABC” product of x1 to x2 quantity and get “PQR” product at Rs. X'){
      this.cartService.deleteCart(product.product_id);
      this.cartService.deleteCart(product.schemeSelected[0].scheme_product_id);
      this.getCart()
    }else{
      this.cartService.deleteCart(product.product_id);
      this.getCart()
    }
    
  }


  

}
