<div class="cart-box" (click)="toggleCart()">
  <svg>
    <use href="assets/svg/icon-sprite.svg#stroke-ecommerce"></use>
  </svg>
  <span class="badge rounded-pill badge-primary">{{ cart.length }}</span>
</div>
<div class="cart-dropdown onhover-show-div" [class.active]="openCart">
  <h6 class="f-18 mb-0 dropdown-title">Cart</h6>
  <ul>
    <ng-container *ngIf="cart.length !== 0 else EMPTY">
      <li *ngFor="let prod of cart">
        <div class="media">
          <ng-container *ngIf="!prod.scheme_prod_discount else OFFER">
            <img class="img-fluid b-r-5 me-3 img-60" src="assets/images/Default_Product_Image.png" alt="" />
          </ng-container>
          <ng-template #OFFER>
            <img class="img-fluid b-r-5 me-3 img-60" src="assets/images/offer_10785639.png" alt="" />
          </ng-template>
          <div class="media-body">
            <span>{{ prod.product_name }}</span>
            <div class="qty-box">
              <div class="input-group">
                <span class="input-group-prepend">
                   <button class="btn quantity-left-minus" [disabled]="prod.scheme_prod_discount" type="button" data-type="minus"  data-field="" (click)="decrementCart(prod)">-</button></span> 
                   <input class="form-control input-number" [disabled]="prod.scheme_prod_discount" type="text" name="quantity" [(ngModel)]="prod.qty" value="1" (keyup)="qtyChEvent.next({event: $event, product: prod})" onlyNumbers/><span class="input-group-prepend">
                  <button class="btn quantity-right-plus" [disabled]="prod.scheme_prod_discount" type="button" data-type="plus" data-field="" (click)="incrementCart(prod)">+</button></span
                >
              </div>
            </div>
            <h6 class="font-primary"><i class="fa fa-rupee"></i> {{ prod.pp_price }}</h6>
          </div>
          <div class="close-circle">
            <a class="bg-danger" (click)="deleteCart(prod)"><app-feather-icons [icon]="'x'"></app-feather-icons></a>
          </div>
        </div>
      </li>
    </ng-container>
    <ng-template #EMPTY>
      <div class="media">
        <div class="media-body">
          <h6 class="font font-danger text-center" style="margin-left: auto; margin-right: auto;"><i class="fa fa-exclamation-circle"></i> Cart is Empty</h6>
        </div>
      </div>
    </ng-template>
    <!-- <li>
      <div class="media">
        <img class="img-fluid b-r-5 me-3 img-60" src="assets/images/other-images/cart-img.jpg" alt="" />
        <div class="media-body">
          <span>Furniture Chair for Home</span>
          <div class="qty-box">
            <div class="input-group">
              <span class="input-group-prepend"> <button class="btn quantity-left-minus" type="button" data-type="minus" data-field="">-</button></span> <input class="form-control input-number" type="text" name="quantity" value="1" /><span class="input-group-prepend">
                <button class="btn quantity-right-plus" type="button" data-type="plus" data-field="">+</button></span
              >
            </div>
          </div>
          <h6 class="font-primary">$500.00</h6>
        </div>
        <div class="close-circle">
          <a class="bg-danger" href="#"><app-feather-icons [icon]="'x'"></app-feather-icons></a>
        </div>
      </div>
    </li> -->
    <!-- <li class="total">
      <h6 class="mb-0 font font-primary">Sub Total : <span class="f-right"><i class="fa fa-rupee"></i> {{ totalAmount | number: '1.2-2' }}</span></h6><hr>
    </li> -->
    <li class="text-center">
      <!-- <a class="d-block mb-3 view-cart f-w-700" href="cart.html">Go to your cart</a> -->
      <a class="btn btn-primary view-checkout" routerLink="/cart">Checkout</a></li>
  </ul>
</div>
