<form class="row row-cols-sm-auto" style="margin-left:0 ; margin-right: 0;">
    <div class="col-12">
        <div class="dp-hidden position-absolute">
            <!-- Keep the date picker for fromDate -->
            <div class="input-group">
                <input
                    name="datepicker"
                    class="form-control"
                    ngbDatepicker
                    #datepicker="ngbDatepicker"
                    [autoClose]="'outside'"
                    (dateSelect)="onDateSelection($event)"
                    [displayMonths]="2"
                    [dayTemplate]="t"
                    outsideDays="hidden"
                    [startDate]="fromDate!"
                    tabindex="-1"
                />
                <ng-template #t let-date let-focused="focused">
                    <span
                        class="custom-day"
                        [class.focused]="focused"
                        [class.range]="isRange(date)"
                        [class.faded]="isHovered(date) || isInside(date)"
                        (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null"
                    >
                        {{ date.day }}
                    </span>
                   
                </ng-template>
            </div>
        </div>
        <div class="input-group">
            <!-- Combine fromDate and toDate into a single input field -->
            <input
                #dpDateRange
                class="form-control"
                placeholder="yyyy-mm-dd - yyyy-mm-dd" 
                name="dpDateRange"
                [value]="formatter.format(fromDate) + ' - ' + formatter.format(toDate)"
                (input)="updateDateRange(dpDateRange.value)"/>
            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
        </div>
    </div>
</form>
