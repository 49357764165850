<div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="close" (click)="modal.dismiss('cancel')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ message }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('confirm')">Confirm</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
  </div>
  