import { Injectable } from '@angular/core';
import { AppSettings } from '../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { log } from 'console';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public apiUrl = AppSettings.apiUrl;
  public user: any={};

  localstorageData(){
    let param = JSON.parse(localStorage.getItem("user"));
    this.user = param;
  }

  constructor(private http: HttpClient) { }

  public cart;
  public totalTax;

  private cartData = new BehaviorSubject<any>(null);


  addToCart(product){
    let inCart;
    let cartProduct;
    let cart = JSON.parse(localStorage.getItem('cart'))
    cart.forEach(element => {
      if(element.product_id === product.product_id){
        if(product.schemeSelected){
          element.qty = 1;
          inCart = true;
        }else{
          element.qty += 1;
          inCart = true;
        }
        
      }
    });
    if(!inCart){
      
      product.qty = product.scheme_prod_discount ? product.scheme_allocated_qty : 1;
      product.notes = '';
      product.prod_total = product.scheme_prod_discount ? product.scheme_allocated_qty * product.pp_price : product.pp_price; 
      cart.push(product);
    }
    localStorage.setItem('cart',JSON.stringify(cart))
    this.getCart();
    
  }

  updateCart(product){
    this.getCart();
    this.cart.forEach(prod =>{
      if(product.product_id === prod.product_id){
        this.cart.pop(prod);
        this.cart.push(product);
        localStorage.setItem('cart',JSON.stringify(this.cart));
        return;
      }
    })
    this.getCart();

  }

  getCart(){
    this.cart = JSON.parse(localStorage.getItem('cart'));
    // return this.cart;
    this.cartData.next(this.cart)
    return this.cartData.asObservable();
  }

  incrementCart(prodName, price){
    this.getCart();
    this.cart.forEach((elem)=>{
      if(elem.product_name === prodName){
        elem.qty += 1;
        elem.pp_price = price;
        elem.prod_total = elem.pp_price *elem.qty;
      }
    });
    localStorage.setItem('cart',JSON.stringify(this.cart));
    // this.cartData.next(this.cart)
    this.getCart()
  }

  decrementCart(prodName, price){
    this.getCart();
    this.cart.forEach((elem)=>{
      if(elem.product_name === prodName){
        if(elem.qty > 1){
          elem.qty -= 1;
          elem.pp_price = price;
          elem.prod_total = elem.pp_price *elem.qty;
        }
      }
    });
    localStorage.setItem('cart',JSON.stringify(this.cart));
    // this.cartData.next(this.cart)
    this.getCart()
  }

  qtyChange(prodName, value, price){
    this.getCart();
    this.cart.forEach((elem)=>{
      if(elem.product_name === prodName){   
        console.log('elem prev', elem);
          
        elem.qty = value;
        elem.pp_price = price;
        elem.prod_total = elem.pp_price * elem.qty;

        console.log(elem);
        
      }
    });
    console.log(this.cart);
    
    localStorage.setItem('cart',JSON.stringify(this.cart));
    this.cartData.next(this.cart)

  }

  deleteCart(prodId){
    // this.cart.forEach((elem)=>{
    //   if(elem.product_id === prodId){
    //     let index = 
    //     // this.cart.pop(elem);
    //   }
    // });
    let index = this.cart.findIndex(el=> el.product_id == prodId)
    console.log(index);
    
    if(index != -1 ){
      this.cart.splice(index,1);
      console.log(this.cart)
      
      localStorage.setItem('cart',JSON.stringify(this.cart));
      this.cartData.next(this.cart)
    }
  }

  placeOrder(order){
    this.localstorageData();

    

    const httpOptions ={
      headers: new HttpHeaders({'Content-Type':  'application/json',
      'Authorization': 'Bearer ' + this.user.token,})
    }

    return this.http.post(this.apiUrl + "v4/insertOrder", order, httpOptions)
  }

  saveNotes(product_id, notes){
    let cart = JSON.parse(localStorage.getItem('cart'));
    cart.forEach(product =>{
      if(product.product_id === product_id){
        product.notes = notes;
      }
    })
    localStorage.setItem('cart',JSON.stringify(cart));
    this.cartData.next(cart)
  }

  offerProdQtyChange(offerProdId, baseprodQty, allocatedQty){
    let cart = JSON.parse(localStorage.getItem('cart'));
    cart.forEach(cp =>{
      if(cp.product_id === offerProdId){
        cp.qty = baseprodQty * allocatedQty;
        return;
      }
    })
    localStorage.setItem('cart',JSON.stringify(cart));
  }
}
