import { Injectable } from '@angular/core';
import { AppSettings } from '../../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  public apiUrl = AppSettings.apiUrl;
  public user;

  signIn(email,password){
    let data = {
			'email' : email,
			'password' : password,
			'comp_id' : 749
		}

		const httpOptions = {
		  	headers: new HttpHeaders({
		    	'Content-Type':  'application/json',
		  	})
		};
		return this.http.post(this.apiUrl + "v4/signin", data, httpOptions)
	
  }

  forgotPassword(userId){
    let data = {
			'user_email' : userId,
		}

		const httpOptions = {
		  	headers: new HttpHeaders({
		    	'Content-Type':  'application/json',
		  	})
		};
		return this.http.post(this.apiUrl + "v4/forgetpassword", data, httpOptions)
  }
  localstorageData(){
    let params = JSON.parse(localStorage.getItem("user"));
    this.user = params;
  }

  resetPassword(params){
	this.localstorageData()
    let data = {
		"password": params.password,
		"new_password": params.newPassword,
		"retype_password": params.confirmNewPassword,
		"user_id": this.user.user_id,
		"logged_in_userid": this.user.user_id,
    }
    
    const httpOptions ={
      headers: new HttpHeaders({'Content-Type':  'application/json',
      'Authorization': 'Bearer ' + this.user.token,})
    }

    return this.http.post(this.apiUrl + "v4/changePassword", data, httpOptions);
  }

}
